import React, { memo, useEffect } from 'react';
import Routes from './routes';
import { MainLayout } from './components/layout';
import { AppContainer } from './features/app';
import { getProfileAsync } from './features/auth/auth.slice';
import { useAppDispatch } from './app/hooks';

const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getProfileAsync(null));
  }, []);
  return (
    <MainLayout>
      <AppContainer />
      <Routes />
    </MainLayout>
  );
};

export default memo(App);
